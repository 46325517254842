<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-app-bar flat v-if="can('edit-notification-template')">
        <!-- <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="() => false"
          v-if="originalData.id && $refs.sendTestForm"
          :loading="isLoading"
        >
          Test Gönder
        </v-btn> -->

        <v-btn
          small
          outlined
          color="pink"
          class="float-end me-2"
          @click="$refs.confirmDelete.show('Şablon silinecek. Emin misiniz?')"
          v-if="
            originalData.id &&
            $refs.confirmDelete &&
            can('delete-notification-template') &&
            originalData.system_id === null
          "
          :loading="isLoading"
        >
          Sil
        </v-btn>

        <rs-confirm
          ref="confirmDelete"
          :loading="isLoading"
          @confirmed="handleDeleteConfirmed"
          @cancelled="$refs.confirmDelete.hide()"
        />

        <NotificationTestForm ref="sendTestForm" />
      </v-app-bar>

      <v-card-title>
        Bildirim Şablonu
        <span class="red--text" v-if="id && originalData.system_id">
          &nbsp;Bu bir sistem mesajıdır.
        </span>
      </v-card-title>

      <v-card-text>
        <p v-if="originalData.description">{{ originalData.description }}</p>

        <v-form
          :disabled="disabled"
          @submit.prevent="handleFormSubmit"
          ref="form"
        >
          <v-row dense cols="12">
            <v-col sm="6">
              <rs-text-field
                label="Şablon Adı"
                v-model="formData.name"
                :rules="[rules.required, rules.maxLength(formData.name, 50)]"
                required
                hint="Sakinler göremez"
                :disabled="originalData.system_id"
              />
            </v-col>

            <v-col sm="6">
              <v-checkbox
                v-model="formData.multiple_body"
                label="Bildirim türüne göre metin değişsin"
                :rules="[rules.required]"
                hint="Her bir gönderim yöntemine göre metin girmenizi sağlar"
                persistent-hint
                :disabled="originalData.system_id"
              />
            </v-col>
          </v-row>

          <v-row dense cols="12">
            <v-col sm="4" lg="2">
              <v-checkbox
                v-model="formData.sms_available"
                label="SMS"
                :rules="[rules.required]"
                :hide-details="auto"
                :disabled="originalData.system_id"
              />
            </v-col>
            <!-- <v-col sm="4" lg="2">
              <v-checkbox
                v-model="formData.email_available"
                label="E-posta"
                :rules="[rules.required]"
                :hide-details="auto"
                :disabled="originalData.system_id"
              />
            </v-col> -->
            <v-col sm="4" lg="2">
              <v-checkbox
                v-model="formData.mobile_push_available"
                label="Mobil Bildirim"
                :rules="[rules.required]"
                :hide-details="auto"
                :disabled="originalData.system_id"
              />
            </v-col>
          </v-row>

          <v-row dense cols="12" v-if="!formData.multiple_body">
            <v-col sm="8">
              <rs-textarea
                label="Mesaj"
                :rules="[rules.required, rules.maxLength(formData.body, 500)]"
                v-model="formData.body"
                rows="5"
                required
                ref="body"
                :disabled="id && !originalData.editable"
              />

              <div class="elevation-2 p-2 mt-2" v-if="example">
                <strong>Örnek Mesaj:</strong>
                <pre>{{ example }}</pre>
              </div>
            </v-col>

            <v-col sm="4">
              <VariableCloud
                :system-id="originalData.system_id"
                @select="addVariable"
              />
            </v-col>
          </v-row>

          <v-tabs
            v-model="selectedTab"
            v-if="formData.multiple_body"
            class="mt-5"
            @change="handleTabChange"
          >
            <v-tab href="#sms" v-if="formData.sms_available"> SMS </v-tab>
            <v-tab href="#email" v-if="formData.email_available">
              E-posta
            </v-tab>
            <v-tab href="#mobile-push" v-if="formData.mobile_push_available">
              Mobil Bildirim
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="selectedTab" v-if="formData.multiple_body">
            <v-tab-item class="py-4" value="sms">
              <v-row dense cols="12">
                <v-col sm="8" md="7">
                  <rs-textarea
                    label="SMS Mesajı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.body_sms, 280),
                    ]"
                    v-model="formData.body_sms"
                    rows="5"
                    required
                    ref="body_sms"
                    :disabled="id && !originalData.editable"
                  />

                  <div class="elevation-2 p-2 mt-2" v-if="example">
                    <strong>Örnek Mesaj:</strong>
                    <pre>{{ example }}</pre>
                  </div>
                </v-col>

                <v-col sm="4" md="5">
                  <VariableCloud
                    :system-id="originalData.system_id"
                    @select="addVariable"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="py-4" value="email">
              <v-row dense cols="12">
                <v-col sm="8" md="7">
                  <rs-textarea
                    label="E-posta Mesajı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.body_email, 2000),
                    ]"
                    v-model="formData.body_email"
                    rows="5"
                    required
                    ref="body_email"
                    :disabled="id && !originalData.editable"
                  />

                  <div class="elevation-2 p-2 mt-2" v-if="example">
                    <strong>Örnek Mesaj:</strong>
                    <pre>{{ example }}</pre>
                  </div>
                </v-col>

                <v-col sm="4" md="5">
                  <VariableCloud
                    :system-id="originalData.system_id"
                    @select="addVariable"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="py-4" value="mobile-push">
              <v-row dense cols="12">
                <v-col sm="8" md="7">
                  <rs-textarea
                    label="Mobil Bildirim Mesajı"
                    :rules="[
                      rules.required,
                      rules.maxLength(formData.body_mobile_push, 100),
                    ]"
                    v-model="formData.body_mobile_push"
                    rows="5"
                    required
                    ref="body_mobile_push"
                    :disabled="id && !originalData.editable"
                  />

                  <div class="elevation-2 p-2 mt-2" v-if="example">
                    <strong>Örnek Mesaj:</strong>
                    <pre>{{ example }}</pre>
                  </div>
                </v-col>

                <v-col sm="4" md="5">
                  <VariableCloud
                    :system-id="originalData.system_id"
                    @select="addVariable"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <rs-form-buttons
            v-if="!disabled"
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";
import VariableCloud from "../VariableCloud";

export default {
  name: "NotificationTemplateForm",
  mixins: [hasForm, hasPermissions],
  components: { VariableCloud },
  computed: {
    ...mapGetters(["clusterId", "companyId"]),
  },
  watch: {
    id: {
      handler(newValue) {
        if (newValue) {
          this.load();
        }
      },
      immediate: true,
    },
    "formData.multiple_body"(newValue) {
      if (newValue) {
        if (this.formData.sms_available) {
          this.activeModel = "body_sms";
        } else if (this.formData.email_available) {
          this.activeModel = "body_email";
        } else {
          this.activeModel = "body_mobile_push";
        }
      } else {
        this.activeModel = "body";
      }

      this.updateExample(this.formData[this.activeModel]);
    },
    "formData.body"(value) {
      this.updateExample(value);
    },
    "formData.body_sms"(value) {
      this.updateExample(value);
    },
    "formData.body_email"(value) {
      this.updateExample(value);
    },
    "formData.body_mobile_push"(value) {
      this.updateExample(value);
    },
  },
  data() {
    return {
      selectedTab: "sms",
      activeModel: "body",
      example: "",
      formData: {
        name: null,
        multiple_body: false,
        body: null,
        body_sms: null,
        body_email: null,
        body_mobile_push: null,
        sms_available: true,
        email_available: true,
        mobile_push_available: true,
      },
    };
  },
  methods: {
    ...mapActions(["updateCompanyId", "updateClusterId"]),
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;

      const formData = Object.assign({}, this.formData);

      if (!this.id) {
        if (this.companyId) {
          formData.company_id = this.companyId;
        } else {
          formData.cluster_id = this.clusterId;
        }

        this.$api
          .post("notification-templates", formData)
          .then((response) => {
            this.$toast.success(this.$t("saved"));
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.$router.back();
            } else {
              this.$router.replace({
                name: "communication.notification-templates.edit",
                params: { id: response.data.data.id },
              });
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$api
          .put(`notification-templates/${this.id}`, formData)
          .then(() => {
            this.$toast.success(this.$t("saved"));
            this.$emit("saved");
            if (event && event.closeOnSave) {
              this.$router.back();
            }
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("notification-templates", this.id)
        .then((response) => {
          this.loadData(response);
          if (this.originalData.cluster_id) {
            this.updateClusterId(this.originalData.cluster_id);
          }

          if (this.originalData.company_id) {
            this.updateCompanyId(this.originalData.company_id);
          }

          if (this.originalData.multiple_body) {
            this.handleTabChange("sms");
          } else {
            this.handleTabChange();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleTabChange(tabValue) {
      if (tabValue === "sms") {
        this.activeModel = "body_sms";
      } else if (tabValue === "email") {
        this.activeModel = "body_email";
      } else if (tabValue === "mobile-push") {
        this.activeModel = "body_mobile_push";
      } else {
        this.activeModel = "body";
      }

      this.updateExample(this.formData[this.activeModel]);
    },
    addVariable(variable) {
      this.formData[this.activeModel] = this.formData[this.activeModel] || "";

      if (
        this.$refs[this.activeModel] &&
        document.activeElement &&
        document.activeElement.tagName === "TEXTAREA"
      ) {
        const start = document.activeElement.value.substr(
          0,
          document.activeElement.selectionStart
        );
        const end = document.activeElement.value.substr(
          document.activeElement.selectionStart
        );

        this.formData[this.activeModel] = start + variable.value + end;
      } else {
        this.formData[this.activeModel] += variable.value;
      }
    },
    updateExample(model) {
      // eslint-disable-next-line
      console.log(model);
      this.example = model;

      for (const index in this.variables) {
        this.example = this.example.replace(
          new RegExp(this.variables[index].value, "gi"),
          this.variables[index].example
        );
      }

      // eslint-disable-next-line
      console.log(this.example);
    },
    handleDeleteConfirmed() {
      if (!this.id || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`notification-templates/${this.id}`)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          this.$router.replace({
            name: "communication.notification-templates.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style>
pre {
  white-space: break-spaces;
}
</style>
